import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route, BrowserRouter } from "react-router-dom"
import './App.css';
import './responsive.css'

import Home from './Pages/home/Home';
import AppBar from './components/appbar/AppBar';
import Footer from './components/footer/Footer';
import About from './Pages/about/About';

function App() {
  return (
    <>
     <BrowserRouter>
     <AppBar/>
        <Routes>
       
          <Route path="/" element={<Home/>} />
          <Route path="/about" element={<About/>} />
          
        </Routes>
        <Footer/>
     </BrowserRouter>
    </>
  );
}

export default App;
