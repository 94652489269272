import React from 'react'
import { Button } from 'react-bootstrap'
import '../button/Buttons.css'
const Buttons = ({label,onclick}) => {
  return (
    <div>
        <Button onClick={onclick} className='clickon'>{label}</Button>
    </div>
  )
}

export default Buttons