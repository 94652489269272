import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import Buttons from '../../components/button/Buttons'
import '../about/about.css'
import { RiSettings2Fill } from "react-icons/ri";
const About = () => {
  return (
    <>
        {/* about banner start */}
        <Container className='padding'>
            <Row>
                <Col lg="12">
                <div className='banner-text bold whites'>
                    <div >Leading Evolution</div>
                    <div className='italic'>Shaping Futures</div>
                </div>
                </Col>
            </Row>
        </Container>
        {/* about banner end */}
        {/* about section start */}
        <Container className='padding'>
            <Row>
                <Col lg="6" className='py-3'>
                    <div className="about-image">
                        <img src={require('../../assets/images/about.jpg')}  className='img-fluid w-100' alt='about company'/>
                    </div>
                </Col>
                <Col lg="6" className='py-3'>
                   <div className='spacegrotesk lh-2'>
                        <p className=' about-quote'>"Revolutionizing Business Operations with Premier Mobile Apps"</p>
                        <p>Welcome to <span className='bold com'>Zentexus Technologies!</span>, the best mobile application development company dedicated to turning your innovative ideas into reality. In today’s fast-paced digital world, having a robust and user-friendly mobile application is essential for business success.</p>
                        <p>As pioneers in the mobile application industry, we combine cutting-edge technology with creativity and strategic thinking.</p>
                        <p> Our team of experienced developers, designers, and strategists work collaboratively to deliver applications that are scalable, secure, and visually stunning. We pride ourselves on our ability to understand the unique challenges of various industries and provide tailored solutions that drive growth and efficiency.</p>
                   </div>
                   <div>
                    <Buttons label={<>Get Quotes</>}/>
                   </div>
                </Col>
            </Row>
        </Container>
         {/* about section end */}
        <div className='quality'>
            <Container className='margin'>
                <Row>
                    <Col lg="3" className='py-3'>
                        <div className='d-flex align-self-center justify-content-center'>
                            <div className='setting'><RiSettings2Fill /></div>
                            <div className='setting-text'>Innovation</div>
                        </div>
                    </Col>
                    <Col lg="3" className='py-3'>
                        <div className='d-flex align-self-center justify-content-center'>
                            <div className='setting'><RiSettings2Fill /></div>
                            <div className='setting-text'>Integrity</div>
                        </div>
                    </Col>
                    <Col lg="3" className='py-3'>
                        <div className='d-flex align-self-center justify-content-center'>
                            <div className='setting'><RiSettings2Fill /></div>
                            <div className='setting-text'>Customer Focus</div>
                        </div>
                    </Col>
                    <Col lg="3" className='py-3'>
                        <div className='d-flex align-self-center justify-content-center'>
                            <div className='setting'><RiSettings2Fill /></div>
                            <div className='setting-text'>Excellence</div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
      <div className='process'>
            <Container className='padding'>
                <Row className='justify-content-center'>
                    <Col lg="12" className='py-3'>
                        <div className='com fs-30 spacegrotesk text-center'> Simple Step Follow to Complete Work</div>     
                    </Col>
                    <div className='margin'>
                        <Row className='justify-content-center'>
                            <Col lg="3" className='py-3'>
                                <div className='process-step'>
                                    <div className='process-content'>
                                        <div className='spacegrotesk com fs-30'>Ideation & Blueprinting </div>
                                        <div className='process-step-text'>
                                            Develop engaging and effective design blueprints.
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="3" className='py-3'>
                                <div className='process-step'>
                                    <div className='process-content'>
                                        <div className='spacegrotesk com fs-30 '>Develope & Assurance </div>
                                        <div className='process-step-text'>
                                            Code and build the app with advanced technologies.
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="3" className='py-3'>
                                <div className='process-step'>
                                    <div className='process-content'>
                                        <div className='spacegrotesk com fs-30'>Launch & <br></br> Sustain </div>
                                        <div className='process-step-text'>
                                            Launch the app smoothly and efficiently.
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Row>
            </Container>
      </div>
         
    
       
    </>
  )
}

export default About