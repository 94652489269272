import React from 'react';
import { Link } from 'react-router-dom';
import '../appbar/AppBar.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { MdArrowOutward } from "react-icons/md";

const AppBar = () => {
  return (
    <>
    <Navbar expand="lg" className="nav-bg">
      <Container className='px-lg-5'>
        <Navbar.Brand as={Link} to="/" className='logo mr-0'>Zentexus<span className='com'>.Com</span></Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse id="nav-bar">
          <Nav className="mx-auto">
            <Nav.Link as={Link} to="/" className='mx-3'>Home</Nav.Link>
            <Nav.Link as={Link} to="/about" className='mx-3'>About</Nav.Link>
            <Nav.Link as={Link} to="/services" className='mx-3'>Services</Nav.Link>
            <Nav.Link as={Link} to="/contact" className='mx-3'>Contact Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <div className=' d-lg-block d-none'>
          <Nav.Link as={Link} to="/contact" className='talk'>
            <div>Let's Talk</div>
            <div><MdArrowOutward /></div>
          </Nav.Link>
        </div>
       
      </Container>
    </Navbar>
    </>
  )
}

export default AppBar;
