import React, { useEffect } from 'react';
import { Container, Row,Col } from 'react-bootstrap'
import '../home/Home.css'
import '../home/MobileHome.css'
import { MdArrowForwardIos } from "react-icons/md";
import { GoArrowUpRight } from "react-icons/go";
import { RiUserHeartLine } from "react-icons/ri";
import { RiUserSettingsLine } from "react-icons/ri"
import { MdOutlineContactSupport } from "react-icons/md";
import { MdOutlineDesignServices } from "react-icons/md";
import Carousel from '../../components/carousel/carousel'
const Home = () => {
  useEffect(() => {
    const textElement = document.querySelector('.circle-text p');
    if (textElement) {
      const text = textElement.innerText;
      textElement.innerHTML = text.split('').map(
        (char, i) => `<span style="transform:rotate(${i * 5.1}deg)">${char}</span>`
      ).join('');
    }
  }, []);
      return (
    <>
      <>
      <Container className='padding px-lg-5 px-4'>
            <Row className=''>
                <Col lg="8" className='py-3'>
                    <div className="banner-text bold">
                        <div className='whites'>Solutions Beyond</div>
                        <div className='italic'>Expectations</div>
                    </div>
                </Col>
            </Row>
        </Container>
      </>
       <>
        <Container className='padding px-lg-5 px-4'>
          <Row className=''>
            <Col lg="12" className='py-3'>
              <div className="text-center banner-image">
                 <img src={require('../../assets/images/banner.jpg')}  className='img-fluid w-100' alt='website developer'/>
              </div>
            </Col>
          </Row>
        </Container>
       </>
       <>
        <Container className='padding px-4 px-lg-5'>
          <Row className=''>
            <Col lg="4" className='py-3 align-self-center '>
              <div className="text-center">
                <div className='circle-letter'>
                    <div className='circle-text'><p> S t u n n i n g . R e s p o n s i v e . S e c u r e  W e b s i t e s .</p></div>
                </div>
              </div>
            </Col>
            <Col lg="8">
              <div className='bold com d-flex title'> <div><MdArrowForwardIos /></div>  <div>About Company</div></div>
              <div className='spacegrotesk lh-2'>
              <div className='about-quote'>"Unleashing Innovative Technology Solutions for Your Business"</div>
                <p  className='fs-20'>At <span className='bold com'>Zentexus</span>, we believe that a
                website is more than just a digital presence. It's an 
                opportunity to tell your story, showcase your brand, and
                connect with your audience. Our team of expert web
                developers, designers, and strategists work together to
                craft custom websites that drive results. Whether you're
                looking to revamp your existing site or launch a new
                one, we'll help you create a stunning, responsive, and
                secure online presence that exceeds your expectations.</p>
              </div>
            </Col>
          </Row>
        </Container>
       </>
       <>
        <Container  className='py-5 px-lg-5'> 
          <Row className='justify-content-center'>
            <Col lg="12" className='py-3'>
              <div className="banner-text bold">
                <div>digital Crafting</div>
                <div className='italic'>Partner</div>
              </div>
            </Col>
          </Row>
        </Container>
       </>
       <>
        <Container className='padding px-lg-5 px-4'>
          <Row className='justify-content-center'>
            <Col lg='12' className='py-4'>
                <div className='bold title com text-center py-4'> Our Services</div>
                  <div></div>
            </Col>
            <Col lg="4" className='py-3'>
              <div className='service-box'>
                <div className='service-icon'>
                    <img src={require('../../assets/images/web.png')}  className='img-fluid' alt='website developing' title='website developing'/>
                </div>
                <div className='go-link'><GoArrowUpRight /> </div>
                <div className='web-circle'></div>
                <div className='service-title fs-20 bold py-3'>Web Development</div>
                <div className='service-desc '>
                  <img src={require('../../assets/images/website.jpg')}  className='img-fluid' alt='website developing' title='website developing'/>
                </div>
              </div>
            </Col>
            <Col lg="4" className='py-3'>
              <div className='service-box'>
              <div className='service-icon'>
                <img src={require('../../assets/images/mobile.png')}  className='img-fluid' alt='mobile app developing' title='mobile app developing'/>
              </div>
              <div className='go-link'><GoArrowUpRight /> </div>
              <div className='web-circle'></div>
              <div className='service-title fs-20 bold py-3'>Mobile Development</div>
                <div className='service-desc'>
                  <img src={require('../../assets/images/mobileapp.jpg')}  className='img-fluid' alt='mobile app developing' title='mobile app developing'/>
                </div>
              </div>
            </Col>
            <Col lg="4" className='py-3'>
              <div className='service-box'>
              <div className='service-icon'>
                  <img src={require('../../assets/images/dm.png')}  className='img-fluid' alt='digital marketing' title='digital marketing'/>
              </div>
              <div className='go-link'><GoArrowUpRight /> </div>
              <div className='web-circle'></div>
                <div className='service-title fs-20 bold py-3'>Digital Marketing</div>
                <div className='service-desc spacegrotesk'>
                  <img src={require('../../assets/images/dm.jpg')}  className='img-fluid' alt='digital marketing' title='digital marketing'/>
                </div>
              </div>
            </Col>
            <Col lg="4" className='py-3'>
              <div className='service-box'>
              <div className='service-icon'>
                <img src={require('../../assets/images/maintain.png')}  className='img-fluid' alt='website maintainence' title='website maintainence'/>
              </div>
              <div className='go-link'><GoArrowUpRight /> </div>
              <div className='web-circle'></div>
                <div className='service-title fs-20 bold py-3'>Web Maintainence</div>
                <div className='service-desc spacegrotesk'>
                  <img src={require('../../assets/images/maintain.jpg')}  className='img-fluid' alt='website maintainence' title='website maintainence'/>
                </div>
              </div>
            </Col>
            <Col lg="4" className='py-3'>
              <div className='service-box'>
              <div className='service-icon'>
                <img src={require('../../assets/images/host.png')}  className='img-fluid' alt='website hosting and domain' title='website hosting and domain'/>
              </div>
              <div className='go-link'><GoArrowUpRight /></div>
              <div className='web-circle'></div>
              <div className='service-title fs-20 bold py-3'>Domain & Hosting</div>
                <div className='service-desc spacegrotesk'>
                   <img src={require('../../assets/images/hosting.jpg')}  className='img-fluid' alt='website hosting and domain' title='website hosting and domain'/>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
       </>
       <>
       <Container className='padding px-lg-5 px-4'>
        <Row>
          <Col lg="12" className='py-3'>
            <div className='title com bold text-center'>Why We Choose ?</div>
          </Col>
          <Col lg="5" className='py-3 align-self-center'>
            <div className=''>
              <img src={require('../../assets/images/whyimage.jpg')}  className='img-fluid' alt='website hosting and domain' title='website hosting and domain'/>
            </div>
          </Col>
          <Col lg="7" className='py-3'>
            <div className='about-quote spacegrotesk'>
              Discover why businesses trust Zentexus Technologies for their technology needs.
            </div>
            <div className='spacegrotesk lh-2'>
              At Zentexus Technologies, we bring over a decade of experience and a team of dedicated experts to deliver innovative and tailored technology solutions. Our commitment to excellence ensures that we use the latest technologies to drive your business forward. We pride ourselves on our client-centric approach, understanding your unique needs, and providing ongoing support to ensure your success. With a proven track record of successful projects and satisfied clients, we consistently adhere to the highest standards of quality and integrity.
            </div>
            <Row>
              <Col lg="6" className='py-3 align-self-center'>
                <div className='d-flex align-content-center align-items-center'>
                <div className='me-2'>
                  <RiUserSettingsLine size={34} color='#24ff07'/>
                </div>
                  <div className='spacegrotesk fs-20 whites'> Expertise and Experience</div>
                </div>
              </Col>
              <Col lg="6" className='py-3'>
                <div className='d-flex align-content-center align-items-center'>
                  <div className='me-2'>
                  <RiUserHeartLine  size={34} color='#24ff07'/>
                  </div>
                  <div className='spacegrotesk fs-20'> Client-Centric Approach</div>
                </div>
              </Col>
              <Col lg="6" className='py-3'>
                <div className='d-flex align-content-center align-items-center'>
                <div className='me-2 '>
                    <MdOutlineDesignServices size={34} color='#24ff07'/>
                  </div>
                  <div className='spacegrotesk fs-20'> Innovative Solutions</div>
                </div>
              </Col>
              <Col lg="6" className='py-3'>
                <div className='d-flex align-content-center align-items-center'>
                <div className='me-2'>
                  <MdOutlineContactSupport size={34} color='#24ff07'/>
                  </div>
                  <div className='spacegrotesk fs-20'>Reliable Support</div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
       </Container>
       </>

       <>
       <Container className='py-5' fluid>
        <Row>
          <Col lg="12" className='py-4'>
            <h4 className='text-center bold title com'> Technologies We Expertise</h4>
          </Col>
          <Col lg="12">
            <Carousel/>
          </Col>
        </Row>
        </Container>
       </>
    </>
  )
}

export default Home